<script setup lang="ts">
import { $t } from "@/i18n";
import {
	type TTableColumn,
	type TTableRow,
} from "@/components/shared/table/ModernTable.vue";
import { DateInstance } from "@/assets/js/dateHelper";
import { openConfirmModal } from "@/components/modals/modalDefinitions";
import api from "@/api";
// import { useToastStore } from "@/store/toast";
import { useRouter } from "vue-router";
import { useTokenStore } from "@/store/token";

definePage({
	name: "NotificationsPage",
});

const date = ref<string | null>(null);
const scroll = ref(true);
// const toast = useToastStore();
const router = useRouter();
const token = useTokenStore();

// const NOTIFICATION_TYPES = {
//   APP_STATUS_CHANGE: 'ASC', // candidate
//   APPLICATION_MESSAGE: 'AMSG', // all app roles review if needed
//   EVENT_INVITE: 'EVTI', // al users
//   INTERNAL_MESSAGEL: 'IMSG', // all users review if needed

//   MANAGE_REQUEST: 'MNGR', // agency
//   MANAGE_ACCEPT: 'MNGA', // candidate
//   MANAGE_DECLINE: 'MNGD', // candidate
//   MANAGE_DELETE: 'DMNG', //candidate
//   MANAGE_REQUEST_DELETE: 'MNRD', // agency

//   MANAGE_ACCEPT_DELETE: 'MNDA', // candidate
//   MANAGE_DECLINE_DELETE: 'MNDD', // candidate
//   APPLICATION_INVITE: 'AINI', // b2b user

//   APPLICATION_INVITE_ACCEPT: 'AINA', // agency
//   UPDATE_ROLE: 'UPDR', // agency member
//   NEW_PAYING_AGENCY: 'NPA', // admin
//   REPORTED_LISTING: 'REP', // admin
//   AGENCY_SUBSCRIPTION_EXPIRING: 'SUBE', // admin
// }
const selectedNotType = ref<{ id: string; label: string }>(null);
const not_list_initial = ref([
	{ id: "ASC", label: $t(`app.notificationStatus.ASC`), permissions: [1] }, // candidate
	{
		id: "AMSG",
		label: $t(`app.notificationStatus.AMSG`),
		permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
	}, // all app roles
	{
		id: "EVTI",
		label: $t(`app.notificationStatus.EVTI`),
		permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
	}, // all users
	{
		id: "EVTD",
		label: $t(`app.notificationStatus.EVTD`),
		permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
	}, // all users
	{
		id: "IMSG",
		label: $t(`app.notificationStatus.IMSG`),
		permissions: [1, 2, 3, 4, 5, 6, 7, 8, 9],
	}, // all users
	{
		id: "MNGR",
		label: $t(`app.notificationStatus.MNGR`),
		permissions: [2, 3, 4, 5],
	}, // agency
	{ id: "MNGA", label: $t(`app.notificationStatus.MNGA`), permissions: [1] }, // candidate
	{ id: "MNGD", label: $t(`app.notificationStatus.MNGD`), permissions: [1] }, // candidate
	{ id: "DMNG", label: $t(`app.notificationStatus.DMNG`), permissions: [1] }, // candidate
	{
		id: "MNRD",
		label: $t(`app.notificationStatus.MNRD`),
		permissions: [2, 3, 4, 5],
	}, // agency
	{ id: "MNDA", label: $t(`app.notificationStatus.MNDA`), permissions: [1] }, // candidate
	{ id: "MNDD", label: $t(`app.notificationStatus.MNDD`), permissions: [1] }, // candidate
	{ id: "AINI", label: $t(`app.notificationStatus.AINI`), permissions: [7] }, // b2b user
	{
		id: "AINA",
		label: $t(`app.notificationStatus.AINA`),
		permissions: [2, 3, 4, 5],
	}, // agency
	{
		id: "UPDR",
		label: $t(`app.notificationStatus.UPDR`),
		permissions: [3, 4, 5],
	}, // agency member
	{ id: "NPA", label: $t(`app.notificationStatus.NPA`), permissions: [6, 9] }, // admin
	{ id: "REP", label: $t(`app.notificationStatus.REP`), permissions: [6, 9] }, // admin
	{ id: "SUBE", label: $t(`app.notificationStatus.SUBE`), permissions: [6, 9] }, // admin
]);

const not_list = ref<{ id: string; label: string }[]>([]);
function setNotifyList() {
	not_list.value = not_list_initial.value.filter((item) =>
		item.permissions.includes(token.userData.rol_id),
	);
}
function deselect() {
	selectedNotType.value = null;
}

const columns = reactive<TTableColumn[]>([
	// {
	// 	id: "img_id",
	// 	label: "",
	// },
	{
		id: "usr_name",
		label: "", //$t("app.messages.nameCompanyListing"),
	},
	{
		id: "not_type",
		label: $t("app.settings.notifications"),
	},
	{
		id: "not_created",
		label: $t("app.messages.dateTime"),
	},
	{
		id: "new",
		label: "",
	},
	// {
	// 	id: "action",
	// 	label: "",
	// },
]);

const rows = ref<TTableRow[]>([
	// {
	// 	img_id: 8_092_158_048_697_964,
	// 	usr_name: "Test Prezime",
	// 	cmp_name: "Test Company",
	// 	msg_created: "2024-04-24 13:46:47",
	// 	msg_content:
	// 		"Lorem ipsum dolor sit amet consectetur. Bibendum et iaculis eu volutpat.",
	// },
]);

async function getNotifications(reset = false) {
	type TParams = {
		offset: number;
		limit: number;
		includeSeen?: boolean;
		not_type?: string;
		date?: string;
	};
	scroll.value = false;
	if (reset) {
		rows.value = [];
		scroll.value = true;
	}
	const params: TParams = {
		limit: 20,
		offset: rows.value.length,
		includeSeen: true,
	};
	if (selectedNotType.value) {
		params.not_type = selectedNotType.value.id;
	}
	if (date.value) {
		params.date = DateInstance.getUtcFromDate(date.value, "YYYY-MM-DD");
	}
	try {
		console.log("zameni sa apijem za notifikacije koji dobijes", params);
		const res = await api.getNotifications(params);
		// messages.value.push(...res.data.data);
		// messages.value.unshift(...res.data.data.reverse());
		console.log(res);
		rows.value.push(...res.data.data);
		setDataForSeen(rows.value);
		scroll.value = res.data.data.length <= 0 ? false : true;
	} catch (e) {
		console.log(e);
	}
}

function formatDate(date: string, format = "DD. MMM YYYY.") {
	// return dayjs.utc(date).local().format("DD.MM.YYYY.");
	if (date) {
		return DateInstance.getFormatDTLocale(date, format);
	}
}

function setRow(e: TTableRow) {
	// console.log("setRow", e);
	switch (e.not_type) {
		case "ASC": {
			console.log("idi na aplikaciju");
			if (e.not_data?.app_id) {
				void router.push({
					name: "CandidateData",
					params: { id: e.not_data.app_id },
				});
			}

			break;
		}
		case "AMSG": {
			console.log("idi na aplikaciju  pa na poruke");
			if (e.not_data?.app_id) {
				void router.push({
					name: "ChatApplication",
					params: { id: e.not_data.app_id },
				});
			}

			break;
		}
		case "IMSG": {
			console.log("idi na internal poruke");
			void router.push({
				name: "ChatMain",
			});

			break;
		}
		case "MNGR":
		case "MNRD": {
			console.log(" kompanija ide kandaidata izmena ide na listu kandidata");
			void router.push({
				name: "CompanyCandidatesPage",
			});

			// if (e.not_data?.usr_id) {
			// 	void router.push({
			// 		name: "PublicProfile",
			// 		params: { id: e.not_data.usr_id },
			// 	});
			// }

			break;
		}
		case "MNGA":
		case "MNGD":
		case "DMNG":
		case "MNDA":
		case "MNDD": {
			console.log("idi na kandidat // kompaniju ");
			if (e.not_data?.cmp_id)
				void router.push({
					name: "CompanyInformation",
					params: { id: e.not_data?.cmp_id },
				});

			break;
		}
		case "EVTI":
		case "EVTD": {
			if (
				(e.not_data?.evt_id && token.isUserPresentCompany) ||
				token.isUserB2B
			) {
				void router.push({
					name: "CompanyHome",
					// params: { id: e.not_data.evt_id },
				});
			} else {
				void router.push({
					name: "userCalendar",
					// params: { id: e.not_data.evt_id },
				});
			}

			break;
		}
		case "AINI":
		case "AINA": {
			console.log("btb ili kompanija ide na aplikaciju");
			if (e.not_data?.app_id) {
				void router.push({
					name: "CandidateData",
					params: { id: e.not_data.app_id },
				});
			}

			break;
		}
		case "REP": {
			console.log("admin ide na reported listing");
			if (e.not_data.job_id)
				void router.push({
					name: "ReoprtedData",
					params: { id: e.not_data.job_id },
				});

			break;
		}
		case "NPA": {
			console.log("admin treba da ide na admin kompanije -> jedna kompanija");
			if (e.not_data.job_id)
				void router.push({
					name: "AdminCompanyInformation",
					params: { id: e.not_data.job_id },
				});

			break;
		}
		// No default
	}
}

function openDelete(e: any) {
	console.log("delete");
	openConfirmModal(
		{ name: e.not_data.cmp_name, data: e },
		confirmRemove,
		"Delete this notification?",
	);
}

function confirmRemove(e: any) {
	console.log("call api for delete notification");
}

// function mute() {
// 	console.log("mute all notification");
// }

// async function deleteOld() {
// 	console.log("delete");
// 	openConfirmModal(
// 		{ name: $t("app.settings.clearNotifyLastMonth"), data: null },
// 		confirmDeleteOld,
// 		$t("app.settings.deleteNotification"),
// 	);
// }
// async function confirmDeleteOld() {
// 	try {
// 		const res = await api.deleteOldNotifications();
// 		console.log(res);
// 		toast.openToastSuccess($t("app.settings.successDeleteOldMsg"));
// 		await getNotifications(true);
// 	} catch (e) {
// 		console.log(e);
// 		toast.openToastError("");
// 	}
// }

async function setDataForSeen(e: TTableRow[]) {
	const ids = e.filter((obj) => {
		return obj.not_seen === null;
	});
	console.log(e);
	console.log(ids);
	// let run = false;
	for (const id of ids) {
		if (id.not_type === "MNGA" || id.not_type === "DMNG") {
			// run = true;
		}
		await setSeen(id.not_id);
	}
	// if (run) {
	// 	console.log("ddddd");
	// 	await token.renewToken();
	// 	run = false;
	// }
	// setSeen(30);
}
async function setSeen(e: any) {
	const params = {
		not_id: e,
	};
	try {
		console.log("call api for seen notification");
		const res = await api.seenNotifications(params);
		console.log(res);
	} catch (e) {
		console.log(e);
	}
}

watch([selectedNotType, date], async () => {
	await getNotifications(true);
});

function writeMsg(not: string, data: any) {
	switch (not) {
		case "ASC": {
			return `Your application status for the listing “${data.job_title}” has changed to ${data.aps_name}.`; // kada bek ubaci promenjivu // Application status changed
		}
		case "AMSG": {
			// treba razmotriti
			// return `You have a new message in group “${data.chat_type}” for candidate ${data.usr_name} in listing “${data.job_title}”.`; // Application message
			return `You have a new message in group “${data.chat_type ?? data.room}”, in listing “${data.job_title}”`;
		}
		case "EVTI": {
			return `You have been invited to attend a meeting on ${formatDate(data.evt_scheduled, "DD. MMM YYYY. HH:mm")}.`; // Event invite
		}
		case "EVTD": {
			return `The meeting ${data.evt_title} on ${formatDate(data.evt_scheduled, "DD. MMM YYYY. HH:mm")} has been cancelled.`;
		}
		case "MNGR": {
			return `Candidate ${data.usr_name} has requested to be represented by you. Please review the request.`; // Manage request
		}
		case "MNGA": {
			return `Your request for representation by “${data.cmp_name}” is approved.`; // Manage accept
		}
		case "DMNG": {
			return `The company ${data.cmp_name} is no longer managing your account.`; //  Manage delete
		}
		case "MNRD": {
			return `Candidate ${data.usr_name} has withdrawn the request for representation.`; // Manage request delete
		}
		case "MNDA": {
			return `Your request for canceling the representation by “${data.cmp_name}” is approved.`; // Manage accept delete
		}
		case "MNDD": {
			return `Your request for canceling the representation by “${data.cmp_name}” is denied.`; // Manage decline delete
		}
		case "MNGD": {
			return ` Your request for representation by “${data.cmp_name}” is declined.`; // Manage decline
		}
		case "AINI": {
			return `${data.cmp_name} has invited you to participate in the selection of candidate “${data.usr_name}” for listing “${data.job_title}”.`; // Application invite
		}
		case "AINA": {
			return `“${data.b2b_usr_name}” has accepted your invitation to be included in selection for candidate “${data.applicant_name}” on listing “${data.job_title}”.`; // Application invite accept
		}
		case "UPDR": {
			return `Your role in company “${data.cmp_name}” has been updated to “${data.rol_name}”.`; // Update Role
		}
		case "NPA": {
			return `A new company has registered as a subscriber.`; // New paying agency
		}
		case "REP": {
			return `The listing "${data.job_title}" has been reported.`; // Reported listing
		}
		case "SUBE": {
			return `The subscription for company ${data.cmp_name} will run out. Plan: ${data.cmp_plan}.`; // Agency subscription expiring
		}
		// No default
	}
}

onMounted(async () => {
	await getNotifications();
	// await setSeen(123);
	setNotifyList();
});
</script>

<template lang="pug">
.notifications-wrapper
	.up
		.wrapp-title
			.line
			.title {{ $t("app.settings.notifications") }}
	.main
		.wrapp-filter
			.wrapp-first
				.dropdown
					multiselect-form(
						v-model="selectedNotType",
						:close-on-select="true",
						:append-to-body="true",
						label="label",
						value-prop="id",
						:options="not_list",
						:placeholder="$t('app.notificationStatus.selectNotifiType')",
						:can-deselect="true",
						@deselect="deselect"
					)
				.date
					modern-date-picker(
						v-model="date",
						:enable-time-picker="false",
						style="width: 150px",
						placeholder="Date"
					)
			//- button.alt(@click="mute")
				span {{ $t("app.settings.mute") }}
			//- button(@click="deleteOld")
				span {{ $t("app.settings.deleteOld") }}
		modern-table(
			:rows="rows",
			:columns="columns",
			:has-border="false",
			:display-additional-row="false",
			:isInfiniteScroll="scroll",
			style="max-height: 700px",
			@scroll-more="getNotifications"
		)
			template(#[`table-body-cell`]="slotProps")
				td(
					v-if="slotProps.column.id == 'not_created'",
					style="width: 110px",
					:class="{ 'not-seen': !slotProps.row.not_seen }",
					@click="setRow(slotProps.row)"
				)
					.dateTime
						span {{ formatDate(slotProps.row[slotProps.column.id]) }}
						//- br
						span.time {{ formatDate(slotProps.row[slotProps.column.id], "HH:mm") }}
				//- td(
					v-else-if="slotProps.column.id == 'img_id'",
					style="width: 36px; padding-right: 0px",
					:class="{ 'not-seen': !slotProps.row.not_seen }",
					@click="setRow(slotProps.row)"
					)
					avatar-image(
						:user="{ img_id: slotProps.row.not_data.img_id, usr_name: slotProps.row.not_data.usr_name ?? slotProps.row.not_data.cmp_name }"
					)
				td.td-name(
					v-else-if="slotProps.column.id == 'usr_name'",
					:class="{ 'not-seen': !slotProps.row.not_seen }",
					@click="setRow(slotProps.row)"
				)
					.chat-name {{ slotProps.row.not_data.usr_name ?? slotProps.row.not_data.cmp_name }}
					div {{ writeMsg(slotProps.row.not_type, slotProps.row.not_data) }}
					//- div(v-for="(obj, key, index) in slotProps.row.not_data", :key="index")
						//- .cmp-name(v-if="typeof key === 'string' && key !== 'cmp_name'") {{ obj }}
						.cmp-name(v-if="typeof key === 'string' && key === 'evt_title'") {{  $t("app.notificationStatus.evtName")+': '  }}
							|
							span.bold {{ obj }}
						.cmp-name(v-else-if="typeof key === 'string' && key === 'rol_name'") {{  $t("app.notificationStatus.rolName")+': '  }}
							span.bold {{ obj }}
						.cmp-name(v-else-if="typeof key === 'string' && key === 'evt_scheduled'") {{  $t("app.notificationStatus.evtScheduled")+': '  }}
							span.bold {{ formatDate(obj, "DD. MMM YYYY. HH:mm") }}
						.cmp-name(v-else-if="typeof key === 'string' && key === 'b2b_usr_name'") B2B:
							|
							span.bold {{ obj }}
						.cmp-name(
							v-else-if="typeof key === 'string' && key === 'applicant_name'"
						) {{  $t("app.notificationStatus.applicantName")+': '  }}
							span.bold {{ obj }}
						.cmp-name(v-else-if="typeof key === 'string' && key === 'job_title'") {{  $t("app.notificationStatus.jobTitle")+': '  }}
							span.bold {{ obj }}
						.cmp-name(v-else-if="typeof key === 'string' && key === 'usr_name'") {{  $t("app.notificationStatus.name")+': '  }}
							span.bold {{ obj }}
						.cmp-name(v-else-if="typeof key === 'string' && key === 'cmp_plan'") {{  $t("app.notificationStatus.plan")+': '  }}
							span.bold {{ obj }}
						.cmp-name(
							v-else-if="typeof key === 'string' && key === 'cmp_planexpiry'"
						) {{  $t("app.notificationStatus.planExpiry")+': '  }}
							span.bold {{ formatDate(obj, "DD. MMM YYYY. HH:mm") }}
				td(
					v-else-if="slotProps.column.id == 'not_type'",
					:class="{ 'not-seen': !slotProps.row.not_seen }",
					@click="setRow(slotProps.row)"
				) {{ $t(`app.notificationStatus.${slotProps.row.not_type}`) }}
				td.action(
					v-else-if="slotProps.column.id === 'action'",
					:class="{ 'not-seen': !slotProps.row.not_seen }",
					@click="openDelete(slotProps.row)"
				)
					img(src="@/assets/image/actionIcons/delete.svg", alt="delete")
				td(
					v-else-if="slotProps.column.id === 'new'",
					:class="{ 'not-seen': !slotProps.row.not_seen }"
				) 
					.circle(v-if="!slotProps.row.not_seen")
				td(v-else, :class="{ 'not-seen': !slotProps.row.not_seen }") {{ slotProps.row[slotProps.column.id] }}
		//- :rooms="[]"
</template>

<style lang="scss" scoped>
.notifications-wrapper {
	max-width: 1224px;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 30px 30px;
	margin-bottom: 14px;
	min-height: 100%;
	height: fit-content;
	// flex-wrap: wrap;
	// justify-content: space-between;
	border-radius: 10px;
	// border: $border;

	.wrapp-title {
		align-self: flex-start;
		width: fit-content;
		margin-bottom: 30px;

		.line {
			display: block;
			// background: $gradient-line3;
			// height: 5px;
			height: 0;
			width: 100%;
			margin-bottom: 14px;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
			border-radius: 0 0 10px 10px;
		}
		.title {
			display: inline;
			// font-family: Archivo, sans-serif;
			font-size: 16px;
			font-weight: 700;
			line-height: 17px;
			letter-spacing: 0;
			text-align: left;
			color: $text-color;
		}
	}
	.main {
		max-width: 783px;
		width: 100%;
		.wrapp-filter {
			margin-bottom: 30px;
			display: flex;
			justify-content: space-between;
			.wrapp-first {
				display: flex;
			}
			.wrapp-first > div {
				margin: 0 3px;
			}
			.dropdown {
				padding: 1px 11px;
				border-radius: $input-radius;
				outline: none;
				background-color: $background-color;
				border: 1px solid;
				border-color: $input-border-color;
				height: $input-height;
				min-width: 250px;
				max-width: 300px;
				align-items: center;

				input {
					width: 100%;
				}
			}
		}

		td {
			padding: 10px;
			cursor: pointer;
			// font-family: Archivo, sans-serif;
			font-size: 11px;
			font-weight: 400;
			line-height: 15px;
			text-align: left;
			color: $text-color;
		}

		.td-name {
			font-family: "Open Sans", sans-serif;
			.chat-name {
				font-size: 13px;
				font-weight: 700;
				line-height: 15px;
				text-align: left;
			}
			.cmp-name {
				font-size: 13px;
				font-weight: 400;
				line-height: 15px;
				text-align: left;
			}
		}

		.dateTime {
			display: flex;
			height: 40px;
			flex-direction: column;
			justify-content: center;
			// font-family: Archivo, sans-serif;

			span {
				font-size: 13px;
				font-weight: 600;
				line-height: 15px;
				letter-spacing: 0;
				text-align: left;
			}

			.time {
				font-size: 11px;
				font-weight: 400;
				line-height: 15px;
				letter-spacing: 0;
				text-align: left;
			}
		}
	}
	.circle {
		background: $app-accent-color3;
		color: $background-color;
		// padding: 0 6px;
		// border-radius: 10px;
		// font-weight: 700;
		// font-size: 0.9em;

		// new
		width: 15px;
		height: 15px;
		border-radius: 50%;
		margin-right: 5px;
		margin-left: auto;
	}

	.not-seen {
		// background-color: $app-accent-color4;
		animation-name: show-seen;
		animation-duration: 3s;
	}
	.bold {
		font-weight: bold;
	}

	@keyframes show-seen {
		from {
			background-color: $app-accent-color4;
		}
		to {
			background-color: transparent;
		}
	}
}
</style>
